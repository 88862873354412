import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const OfferCreationMaster = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [termNo, setTermNo] = useState(null);
  const [termName, setTermName] = useState("");
  const [descriptions, setDescriptions] = useState([""]);
  const [errors, setErrors] = useState({});
  const [OfferTermsheader, setOfferTermsheader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]);

  const [search2, setSearch2] = useState("");

  const [OfferTermsDetails, setOfferTermsDetails] = useState([]);

  useEffect(() => {
    fetchTermNo();
  }, []);

  const fetchTermNo = async () => {
    setIsLoading(true);

    axios
      .get(`${BASEURL}/getOfferTermsheader`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setOfferTermsheader(response.data);
        setIsLoading(false);
      });
    axios
      .get(`${BASEURL}/getOfferTermDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setOfferTermsDetails(response.data);
        setIsLoading(false);
      });

    try {
      const response = await axios.get(`${BASEURL}/getNewOfferTermsId`, {
        headers: {
          "auth-token": authToken,
        },
      });
      setTermNo(response?.data?.[0]?.NEWOFFERID);
    } catch (error) {
      console.error("Error fetching TERMNO:", error);
    }
  };

  const handleTermNameChange = (e) => {
    setTermName(e.target.value);
  };

  const handleDescriptionChange = (index, value) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = value;
    setDescriptions(newDescriptions);

    if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`description-${index}`]: "Description cannot be empty",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [`description-${index}`]: _, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const addDescription = () => {
    if (descriptions.some((desc) => desc.trim() === "")) {
      alert("Please fill in all descriptions before adding a new one.");
      return;
    }
    setDescriptions([...descriptions, ""]);
  };

  const deleteDescription = (index) => {
    const newDescriptions = descriptions.filter((_, i) => i !== index);
    setDescriptions(newDescriptions);

    setErrors((prevErrors) => {
      const { [`description-${index}`]: _, ...rest } = prevErrors;
      return rest;
    });
  };

  const handleSubmit = async () => {
    if (!termNo) {
      return;
    }
    if (!termName) {
      alert("PLEASE PROVIDE TERM NAME");
      return;
    }

    const confirm = window.confirm("Are you sure you want to save these datas");

    if (!confirm) {
      return;
    }
    if (descriptions.some((desc) => desc.trim() === "")) {
      alert("Please fill in all descriptions before submitting.");
      return;
    }

    const payload = {
      header: {
        TERMNO: Number(termNo),
        TERMNAME: termName,
      },
      details: descriptions.map((description) => ({
        TERMNO: Number(termNo),
        TERMDESCRIPTION: description,
      })),
    };
    console.log(payload);

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${BASEURL}/insertOfferTerms`,
        payload,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      alert(response.data.message);
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Error submitting data:", error);
      alert("Error submitting data. Please try again.");
    }
  };
  const filteredData = OfferTermsheader.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_OFFER_TERM_NAME", "OM_OFFER_TERM_NAME", "OM_CREATED_BY"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    return textMatch;
  });
  const handleRowClick = async (termId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASEURL}/getOfferTermDetails?OM_OFFER_TERM_ID=${termId}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setFilteredDetails(response.data);
    } catch (error) {
      console.error("Error fetching term details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData2 = filteredDetails.filter((item) => {
    const searchData = search2.toLowerCase().split(" ").filter(Boolean);
    if (searchData.length === 0) return true;
    return searchData.every((term) =>
      ["OM_OFFER_TERM_ID", "OM_OFFER_TERM_DESCRIPTION", "OM_CREATED_BY"].some(
        (key) => String(item[key]).toLowerCase().includes(term)
      )
    );
  });

  function refresh() {
    window.location.reload();
  }
  function HandeEditChanges(item) {
    setTermNo(item.OM_OFFER_TERM_ID);
    setTermName(item.OM_OFFER_TERM_NAME);

    // Fetch descriptions associated with the selected term
    const fetchDescriptions = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASEURL}/getOfferTermDetails?OM_OFFER_TERM_ID=${item.OM_OFFER_TERM_ID}`,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );
        // Assuming the response structure contains an array of descriptions
        const descriptionsData = response.data.map(
          (detail) => detail.OM_OFFER_TERM_DESCRIPTION
        );
        setDescriptions(descriptionsData);
      } catch (error) {
        console.error("Error fetching term details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDescriptions();
    scrollToSection();
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50 min-h-[100vh]">
      <NavbarForAllPagesHR />
      <header className="lg:flex justify-center my-1 header__of__main">
        <h1 className="header__of__page">
          {menu ? menu : "TERMS CREATION - OFFER RELATED"}
        </h1>
      </header>
      <div className="container mx-auto lg:w-[60%] p-4 shadow-lg bg-white">
        <section className="lg:flex justify-between w-full">
          <div className="mb-4">
            <label className="block text-gray-700">TERM NO</label>
            <input
              type="text"
              value={termNo || ""}
              readOnly
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[60%]">
            <label className="block text-gray-700">TERM NAME</label>
            <input
              type="text"
              value={termName}
              onChange={handleTermNameChange}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
        </section>
        <div className="mb-4">
          <label className="block text-gray-700 uppercase">
            Add Descriptions
          </label>
          {descriptions.map((description, index) => (
            <div key={index} className="mb-2">
              <div className="flex items-center">
                <textarea
                  value={description}
                  onChange={(e) =>
                    handleDescriptionChange(index, e.target.value)
                  }
                  className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm ${
                    errors[`description-${index}`] ? "border-red-500" : ""
                  }`}
                />
                <button
                  onClick={() => deleteDescription(index)}
                  className="ml-2 p-2 bg-red-500 text-white rounded-md"
                >
                  <RiDeleteBin2Line />
                </button>
              </div>
              {errors[`description-${index}`] && (
                <p className="text-red-500 ml-2">
                  {errors[`description-${index}`]}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="px-4 bg-[#002d62] text-white rounded-md"
          >
            Submit
          </button>
          <button
            onClick={addDescription}
            className="p-2 bg-blue-500 text-white rounded-md"
          >
            Add Description
          </button>
        </div>
      </div>

      <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
        <div>
          <div className="searchhhhhh">
            <p>Search:</p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>

          <aside className="table__video__app uppercase">
            <table class="custom-table">
              <thead class="header-cell">
                <td>OFFER TERM ID.</td>
                <td>TERM NAME</td>
                <td>EDIT</td>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className=" cursor-pointer"
                    onClick={() => handleRowClick(item.OM_OFFER_TERM_ID)}
                  >
                    <td className="text-right">{item.OM_OFFER_TERM_ID}</td>
                    <td className="lg:min-w-[300px]">
                      {item.OM_OFFER_TERM_NAME}
                    </td>
                    <td
                      onClick={(e) => {
                        HandeEditChanges(item);
                        scrollToSection();
                      }}
                    >
                      {" "}
                      <FaRegEdit />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </aside>
        </div>

        <div>
          {filteredDetails.length != 0 && (
            <div className="searchhhhhh">
              <p>Search:</p>

              <input
                className="input_1"
                required
                value={search2}
                onChange={(e) => {
                  setSearch2(e.target.value);
                }}
              ></input>

              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>
          )}
          {filteredDetails.length != 0 && (
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>OFFER TERM ID.</td>
                  <td>OFFER TERM DESCRIPTION</td>
                </thead>
                <tbody>
                  {filteredData2.map((item, index) => (
                    <tr key={index} class="table-row">
                      <td className="text-right">
                        {item.OM_OFFER_TERM_ID ? item.OM_OFFER_TERM_ID : "N/A"}
                      </td>
                      <td>
                        {item.OM_OFFER_TERM_DESCRIPTION
                          ? item.OM_OFFER_TERM_DESCRIPTION
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferCreationMaster;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";

const EMPLOYEESALARYDETAILS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [employeeName, setEmployeeName] = useState("");
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [leavesApplicable, setLeavesApplicable] = useState(null);
  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const hist = useRef(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [systemId, setSystemId] = useState("");
  const [basicPay, setBasicPay] = useState("");
  const [currencyType, setCurrencyType] = useState(null);
  const [lstAmount, setLstAmount] = useState("");
  const [cashBenefits, setCashBenefits] = useState("");
  const [cashAmount, setCashAmount] = useState("");
  const [nonCashBenefits, setNonCashBenefits] = useState("");
  const [nonCashAmount, setNonCashAmount] = useState("");
  const [listOfCashBenefits, setListOfCashBenefits] = useState([]);
  const [listOfNonCashBenefits, setListOfNonCashBenefits] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [cashTable, setCashTable] = useState([]);
  const [nonCashTable, setNonCashTable] = useState([]);
  const [freezeCash, setFreezeCash] = useState(false);
  const [freezeNonCash, setFreezeNonCash] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowIndex1, setSelectedRowIndex1] = useState(null);

  // Filters
  const filtDepartments = listOfDepartments?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "EMPLOYEE CODE",
        " EMPLOYEE ID",
        "EMPLOYEE NAME",
        "NATIONAL ID NO",
        "DEPARTMENT",
        "DESIGNATION",
        "BASICPAY",
        "BASICPAYCURRENCY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    return textMatch;
  });

  useEffect(() => {
    getBenefitsCash();
    getBenefitsNonCash();
    getCurrency();
    allemployees();
  }, []);

  // Fetching List
  const allemployees = () => {
    axios
      .get(`${BASEURL}/allemployees`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfDepartments(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Employees /allemployees",
          err
        );
      });
  };

  const getBenefitsCash = () => {
    axios
      .get(`${BASEURL}/getBenefitsMaster?PM_BENEFITS_TYPE=Cash`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfCashBenefits(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Cash Benefits /getBenefitsMaster",
          err
        );
      });
  };

  const getBenefitsNonCash = () => {
    axios
      .get(`${BASEURL}/getBenefitsMaster?PM_BENEFITS_TYPE=Non Cash`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfNonCashBenefits(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Non Cash Benefits /getBenefitsMaster",
          err
        );
      });
  };

  const getCurrency = () => {
    axios
      .get(`${BASEURL}/getCurrency`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setCurrencyList(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error While fetching List Of Currency /getCurrency", err);
      });
  };

  const getEmployeeBenifitsCash = (empid) => {
    axios
      .get(
        `${BASEURL}/getEmployeeBenifits?OM_EMP_ID=${empid}&OM_EMP_BENEFIT_TYPE=CASH`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        console.log(res?.data);
        setCashTable(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Cash Table /getEmployeeBenifits",
          err
        );
      });
  };

  const getEmployeeBenifitsNonCash = (empid) => {
    axios
      .get(
        `${BASEURL}/getEmployeeBenifits?OM_EMP_ID=${empid}&OM_EMP_BENEFIT_TYPE=NON CASH`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        console.log(res?.data);
        setNonCashTable(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Cash Table /getEmployeeBenifits",
          err
        );
      });
  };

  // Add Cash Benefits
  const AddCashBenefits = () => {
    if (!cashBenefits) {
      alert("Please Supply Cash Benefits");
    } else if (Number(cashAmount) <= 0) {
      alert("Kindly Supply Cash Amount greater than 0");
    } else {
      const newItem = {
        OM_EMP_BENEFIT_NAME: cashBenefits,
        OM_EMP_BENEFIT_AMOUNT: cashAmount,
      };
      const existingRowIndex = cashTable.findIndex(
        (charge) => charge.OM_EMP_BENEFIT_NAME === newItem.OM_EMP_BENEFIT_NAME
      );

      if (existingRowIndex !== -1) {
        // Row already exists, update it
        const updatedItems = [...cashTable];
        updatedItems[existingRowIndex] = newItem;
        setCashTable(updatedItems);
      } else {
        // Row doesn't exist, add it as a new one
        setCashTable([...cashTable, newItem]);
      }
      setCashBenefits("");
      setCashAmount("");
    }
  };

  const RemoveCashBenefits = () => {
    const updatedValues = [...cashTable];
    if (updatedValues.length === 0) {
      alert("There is no Items to be removed");
    } else {
      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setSelectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      setCashTable(updatedValues);
      setCashBenefits("");
      setCashAmount("");
    }
  };

  // Add Non Cash Benefits
  const AddNonCashBenefits = () => {
    if (!nonCashBenefits) {
      alert("Please Supply Non Cash Benefits");
    } else if (Number(nonCashAmount) <= 0) {
      alert("Kindly Supply Non Cash Amount greater than 0");
    } else {
      const newItem = {
        OM_EMP_BENEFIT_NAME: nonCashBenefits,
        OM_EMP_BENEFIT_AMOUNT: nonCashAmount,
      };
      const existingRowIndex = nonCashTable.findIndex(
        (charge) => charge.OM_EMP_BENEFIT_NAME === newItem.OM_EMP_BENEFIT_NAME
      );

      if (existingRowIndex !== -1) {
        // Row already exists, update it
        const updatedItems = [...nonCashTable];
        updatedItems[existingRowIndex] = newItem;
        setNonCashTable(updatedItems);
      } else {
        // Row doesn't exist, add it as a new one
        setNonCashTable([...nonCashTable, newItem]);
      }
      setNonCashBenefits("");
      setNonCashAmount("");
    }
  };

  const RemoveNonCashBenefits = () => {
    const updatedValues = [...nonCashTable];
    if (updatedValues.length === 0) {
      alert("There is no Items to be removed");
    } else {
      if (
        selectedRowIndex1 !== null &&
        selectedRowIndex1 >= 0 &&
        selectedRowIndex1 < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex1, 1);
        setSelectedRowIndex1(null);
      } else {
        updatedValues.pop();
      }
      setNonCashTable(updatedValues);
      setNonCashBenefits("");
      setNonCashAmount("");
    }
  };

  // Saving
  async function Save() {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/updateEmployeeSalaryDetails`,
        {
          EMPID: Number(systemId),
          BASICPAY: Number(basicPay),
          CURRENCY: currencyType,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setIsLoading(false);
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Error while saving Basic Pay /updateEmployeeSalaryDetails`,
        error
      );
      alert(error.response.data.message);
    }
  }

  async function SaveCash() {
    setIsLoading(true);
    let successCount = 0;
    let successMessage = null;
    let errorCount = 0;
    let errorMessage = null;
    for (let index = 0; index < cashTable.length; index++) {
      const value = cashTable[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertEmployeeBenifits`,
          {
            EMPID: Number(systemId),
            EMPCODE: employeeCode,
            BENEFITTYPE: value.OM_EMP_BENEFIT_NAME,
            CASHNONCASH: "CASH",
            AMOUNT: Number(value.OM_EMP_BENEFIT_AMOUNT),
            FREEZE: freezeCash === true ? 1 : 0,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          errorCount++;
          errorMessage = response.data.message;
        }
      } catch (error) {
        setIsLoading(false);
        console.error(`Error while Saving Cash Benefits `, error);
        alert(error.response.data.message);
      }
    }
    setIsLoading(false);
    if (successCount == cashTable.length && successMessage) {
      alert(successMessage);
    }
    if (errorMessage) {
      alert(errorMessage);
    }
  }

  async function SaveNonCash() {
    setIsLoading(true);
    let successCount = 0;
    let successMessage = null;
    let errorCount = 0;
    let errorMessage = null;
    for (let index = 0; index < nonCashTable.length; index++) {
      const value = nonCashTable[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertEmployeeBenifits`,
          {
            EMPID: Number(systemId),
            EMPCODE: employeeCode,
            BENEFITTYPE: value.OM_EMP_BENEFIT_NAME,
            CASHNONCASH: "NON CASH",
            AMOUNT: Number(value.OM_EMP_BENEFIT_AMOUNT),
            FREEZE: freezeNonCash === true ? 1 : 0,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          errorCount++;
          errorMessage = response.data.message;
        }
      } catch (error) {
        setIsLoading(false);
        console.error(`Error while Saving Non Cash Benefits `, error);
        alert(error.response.data.message);
      }
    }
    setIsLoading(false);
    if (successCount == nonCashTable.length && successMessage) {
      alert(successMessage);
    }
    if (errorMessage) {
      alert(errorMessage);
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">
            {menu ? menu : "LEAVE APPLICABLE SETUP FOR THE YEAR"}
          </h1>
        </header>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <section className="flex gap-3 items-center justify-between">
              <div className="searchhhhhh">
                <p>Search:</p>
                <input
                  className="input_1"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <div className="search__icon">
                  {" "}
                  <FaSearch />
                </div>
              </div>
              <div className="flex gap-3">
                <button
                  className="px-2 h-9 bg-[#002d62] text-white rounded-md text-sm"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  New
                </button>
                <button
                  className="px-2 h-9 bg-[#002d62] text-white rounded-md text-sm"
                  onClick={async () => {
                    if (!selectedEmployee) {
                      alert("Kindly Supply Employee Details");
                    } else if (!basicPay) {
                      alert("Kindly Supply Basic Pay");
                    } else if (!currencyType) {
                      alert("Kindly Supply Currency");
                    } else {
                      await Save();
                      if (cashTable.length > 0) {
                        await SaveCash();
                      }
                      if (nonCashTable.length > 0) {
                        await SaveNonCash();
                      }
                      window.location.reload();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>

            <aside className="table__video__app uppercase h-64 mb-4 overflow-auto border-b-2 text-[12px]">
              <table class="custom-table">
                <thead class="header-cell">
                  <td className="w-[200px] text-right">EMPLOYEE ID</td>
                  <td>EMPLOYEE CODE</td>
                  <td>EMPLOYEE NAME</td>
                  <td>NATIONAL ID</td>
                  <td>DEPARTMENT</td>
                  <td>DESIGNATION</td>
                  <td>BASIC PAY</td>
                  <td>BASIC PAY CURRENCY</td>
                </thead>
                <tbody>
                  {filtDepartments.map((item, index) => (
                    <tr
                      key={index}
                      className=" cursor-pointer"
                      onClick={() => {
                        setSelectedEmployee(
                          item["EMPLOYEE NAME"] ? item["EMPLOYEE NAME"] : ""
                        );
                        setEmployeeCode(
                          item["EMPLOYEE CODE"] ? item["EMPLOYEE CODE"] : ""
                        );
                        setSystemId(
                          item[" EMPLOYEE ID"] ? item[" EMPLOYEE ID"] : ""
                        );
                        setBasicPay(item.BASICPAY ? item.BASICPAY : "");
                        setCurrencyType(
                          item.BASICPAYCURRENCY ? item.BASICPAYCURRENCY : ""
                        );
                        getEmployeeBenifitsCash(item[" EMPLOYEE ID"]);
                        getEmployeeBenifitsNonCash(item[" EMPLOYEE ID"]);
                      }}
                    >
                      <td className="w-[200px] text-right">
                        {item[" EMPLOYEE ID"] ? item[" EMPLOYEE ID"] : "N/A"}
                      </td>
                      <td>
                        {item["EMPLOYEE CODE"] ? item["EMPLOYEE CODE"] : "N/A"}
                      </td>
                      <td>
                        {item["EMPLOYEE NAME"] ? item["EMPLOYEE NAME"] : "N/A"}
                      </td>
                      <td>
                        {item["NATIONAL ID NO"]
                          ? item["NATIONAL ID NO"]
                          : "N/A"}
                      </td>
                      <td>{item.DEPARTMENT ? item.DEPARTMENT : "N/A"}</td>
                      <td>{item.DESIGNATION ? item.DESIGNATION : "N/A"}</td>
                      <td>
                        {item.BASICPAY
                          ? item.BASICPAY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td>
                        {item.BASICPAYCURRENCY ? item.BASICPAYCURRENCY : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      <div className="container mx-auto lg:w-[95%] p-4 shadow-lg bg-white">
        <section className="lg:flex  items-center w-full gap-2">
          <div className="mb-4 lg:w-[350px]">
            <label className="block text-gray-700">Selected Employee</label>
            <input
              type="text"
              value={selectedEmployee}
              readOnly
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">Employee Code</label>
            <input
              type="text"
              value={employeeCode}
              readOnly
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">System ID</label>
            <input
              type="text"
              value={systemId}
              readOnly
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right pr-2"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">Basic Pay</label>
            <input
              type="number"
              value={basicPay}
              onChange={(e) => setBasicPay(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right pr-2"
            />
          </div>
          <div className="mb-4 lg:w-[250px]">
            <label className="block text-gray-700">Currency Type</label>
            <Select
              options={[
                ...currencyList.map((item) => ({
                  value: item.FM_CURRENCY_CODE,
                  label: item.FM_CURRENCY_CODE,
                })),
              ]}
              value={
                currencyType
                  ? { value: currencyType, label: currencyType }
                  : { value: "", label: "Select Currency" }
              }
              onChange={(e) => setCurrencyType(e.value)}
              className="w-full"
            />
          </div>
        </section>
        <div className="flex gap-2"></div>
        <main className="flex justify-between gap-3">
          <aside className="w-1/2 bg-slate-50 px-2 rounded">
            <section className="lg:flex justify-between w-full mt-4">
              <div className="mb-4 lg:w-[300px]">
                <label className="block text-gray-700">Cash Benefits</label>
                <Select
                  options={[
                    ...listOfCashBenefits.map((item) => ({
                      value: item.PM_BENEFITS_NAME,
                      label: item.PM_BENEFITS_NAME,
                    })),
                  ]}
                  value={
                    cashBenefits
                      ? { value: cashBenefits, label: cashBenefits }
                      : { value: "", label: "Select Cash Benefits" }
                  }
                  onChange={(e) => {
                    if (!selectedEmployee) {
                      alert("Kindly Select Employee From Table");
                    } else {
                      setCashBenefits(e.value);
                    }
                  }}
                  className="w-full"
                />
              </div>
              <div className="mb-4 lg:w-[250px]">
                <label className="block text-gray-700">
                  Amount (Local Currency)
                </label>
                <input
                  type="number"
                  value={cashAmount}
                  onChange={(e) => setCashAmount(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right pr-2"
                />
              </div>
            </section>
            <section className="flex justify-end gap-3">
              <div className="flex gap-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={freezeCash === true}
                    onChange={(e) => setFreezeCash(!freezeCash)}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 text-gray-700">Freeze</span>
                </label>
              </div>
              <div className="flex gap-2 mt-4">
                <button
                  onClick={() => {
                    AddCashBenefits();
                  }}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  ADD
                </button>
                <button
                  onClick={() => {
                    RemoveCashBenefits();
                  }}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  REMOVE
                </button>
              </div>
            </section>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>BENEFIT NAME</td>
                  <td className="W-[100PX] text-right">AMOUNT</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {cashTable.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td>
                        {item.OM_EMP_BENEFIT_NAME
                          ? item.OM_EMP_BENEFIT_NAME
                          : "N/A"}
                      </td>
                      <td className="W-[100PX] text-right">
                        {item.OM_EMP_BENEFIT_AMOUNT
                          ? item.OM_EMP_BENEFIT_AMOUNT.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setCashBenefits(
                            item.OM_EMP_BENEFIT_NAME
                              ? item.OM_EMP_BENEFIT_NAME
                              : ""
                          );
                          setCashAmount(
                            item.OM_EMP_BENEFIT_AMOUNT
                              ? item.OM_EMP_BENEFIT_AMOUNT
                              : ""
                          );
                          setSelectedRowIndex(index);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit size={25} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </aside>

          <aside className="w-1/2 bg-slate-50 px-2 rounded">
            <section className="lg:flex justify-between w-full mt-4">
              <div className="mb-4 lg:w-[300px]">
                <label className="block text-gray-700">Non-Cash Benefits</label>
                <Select
                  options={[
                    ...listOfNonCashBenefits.map((item) => ({
                      value: item.PM_BENEFITS_NAME,
                      label: item.PM_BENEFITS_NAME,
                    })),
                  ]}
                  value={
                    nonCashBenefits
                      ? { value: nonCashBenefits, label: nonCashBenefits }
                      : { value: "", label: "Select Non Cash Benefits" }
                  }
                  onChange={(e) => {
                    if (!selectedEmployee) {
                      alert("Kindly Select Employee From Table");
                    } else {
                      setNonCashBenefits(e.value);
                    }
                  }}
                  className="w-full"
                />
              </div>
              <div className="mb-4 lg:w-[250px]">
                <label className="block text-gray-700">
                  Amount (Local Currency)
                </label>
                <input
                  type="number"
                  value={nonCashAmount}
                  onChange={(e) => setNonCashAmount(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right pr-2"
                />
              </div>
            </section>
            <section className="flex justify-end gap-3">
              <div className="flex gap-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={freezeNonCash === true}
                    onChange={() => setFreezeNonCash(!freezeNonCash)}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 text-gray-700">Freeze</span>
                </label>
              </div>
              <div className="flex gap-2 mt-4">
                <button
                  onClick={() => {
                    AddNonCashBenefits();
                  }}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  ADD
                </button>
                <button
                  onClick={() => {
                    RemoveNonCashBenefits();
                  }}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  REMOVE
                </button>
              </div>
            </section>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>BENEFIT NAME</td>
                  <td className="W-[100PX] text-right">AMOUNT</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {nonCashTable.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td>
                        {item.OM_EMP_BENEFIT_NAME
                          ? item.OM_EMP_BENEFIT_NAME
                          : "N/A"}
                      </td>
                      <td className="W-[100PX] text-right">
                        {item.OM_EMP_BENEFIT_AMOUNT
                          ? item.OM_EMP_BENEFIT_AMOUNT.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setNonCashBenefits(
                            item.OM_EMP_BENEFIT_NAME
                              ? item.OM_EMP_BENEFIT_NAME
                              : ""
                          );
                          setNonCashAmount(
                            item.OM_EMP_BENEFIT_AMOUNT
                              ? item.OM_EMP_BENEFIT_AMOUNT
                              : ""
                          );
                          setSelectedRowIndex1(index);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit size={25} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </aside>
        </main>
      </div>
    </div>
  );
};

export default EMPLOYEESALARYDETAILS;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
const PAYROLLPROCESSING = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [leaveType, setLeaveType] = useState("");
  const [listOfLeaveTypes, setListOfLeaveTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [fromPass, setfromPass] = useState("");
  const [toPass, settoPass] = useState("");
  const [period, setPeriod] = useState("");

  const hist = useRef(false);

  // Filters
  const filtLeaveTypes = listOfLeaveTypes?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_LEAVE_TYPE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    return textMatch;
  });

  useEffect(() => {
    getLeaveTypes();
  }, []);

  // Fetching List
  const getLeaveTypes = () => {
    axios
      .get(`${BASEURL}/getLeaveTypes`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfLeaveTypes(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Leave Types /getLeaveTypes",
          err
        );
      });
  };

  // Save
  async function Save() {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/insertLeaveType`,
        {
          LEAVETYPE: leaveType,
          LEAVETYPECREATEDBY: user,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setIsLoading(false);
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Error while saving Leave Type Details /insertLeaveType`,
        error
      );
      alert(error.response.data.message);
    }
  }
  const employeeOptions = [
    { value: "john_doe", label: "John Doe" },
    { value: "jane_smith", label: "Jane Smith" },
    { value: "michael_johnson", label: "Michael Johnson" },
    // Add more employee options here
  ];
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">
            {menu ? menu : "PAYROLL PROCESSING"}
          </h1>
        </header>
        <div className="container mx-auto lg:w-[65%] p-4 shadow-lg bg-white">
          <div className=" flex lg:w-full gap-3 justify-end mb-2 text-sm">
            <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
              PROCESS
            </button>
            <button
              onClick={() => {
                setLeaveType("");
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              NEW
            </button>
            <button
              onClick={async () => {
                if (!leaveType) {
                  alert("Kindly Enter Leave Type");
                } else {
                  await Save();
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              SAVE
            </button>
            <button
              onClick={() => {
                window.print();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              PRINT
            </button>
            <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
              CONFIRM
            </button>
            <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
              UNCONFIRM
            </button>
            <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
              CLOSE MONTH
            </button>
          </div>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700"> Period</label>
              <input
                type="date"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4 lg:w-[300px]">
              <label className="block text-gray-700">From </label>
              <input
                type="test"
                value={fromPass}
                onChange={(e) => setfromPass(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4 lg:w-[300px]">
              <label className="block text-gray-700">To</label>
              <input
                type="text"
                value={toPass}
                onChange={(e) => settoPass(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>{" "}
          </section>
        </div>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>

            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td> TYPE</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filtLeaveTypes.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setLeaveType(
                            item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : ""
                          );
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>LEAVE TYPE</td>
                </thead>
                <tbody>
                  {filtLeaveTypes.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PAYROLLPROCESSING;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const DesignationMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [designationCode, setDesignationCode] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [listOfDesignations, setListOfDesignations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const hist = useRef(false);

  // Filters
  const filtDesignations = listOfDesignations?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_DESIGNATION_CODE", "OM_DESIGNATION_NAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    return textMatch;
  });

  useEffect(() => {
    getDesignation();
  }, []);

  // Fetching List
  const getDesignation = () => {
    axios
      .get(`${BASEURL}/getDesignation`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        setListOfDesignations(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Departments /getDesignation",
          err
        );
      });
  };

  // Save
  async function Save() {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/insertDesignation`,
        {
          DESIGNATIONCODE: designationCode,
          DESIGNATIONNAME: designationName,
          DESIGNATIONCREATEDBY: user,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setIsLoading(false);
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Error while saving Designation Details /insertDesignation`,
        error
      );
      alert(error.response.data.message);
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="container mx-auto lg:w-[60%] p-4 shadow-lg bg-white">
          <div className="flex justify-end gap-2 mb-2">
            <button
              onClick={() => {
                setDesignationCode("");
                setDesignationName("");
                hist.current = false;
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              NEW
            </button>
            <button
              onClick={async () => {
                if (!designationCode) {
                  alert("Kindly Enter Designation Code");
                } else if (!designationName) {
                  alert("Kindly Enter Designation Name");
                } else {
                  await Save();
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              SAVE
            </button>
            <button
              onClick={() => {
                window.print();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              PRINT
            </button>
          </div>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[35%]">
              <label className="block text-gray-700">DESIGNATION CODE</label>
              <input
                type="text"
                value={designationCode}
                onChange={(e) => setDesignationCode(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                disabled={hist.current === true}
              />
            </div>
            <div className="mb-4 lg:w-[60%]">
              <label className="block text-gray-700">DESIGNATION NAME</label>
              <input
                type="text"
                value={designationName}
                onChange={(e) => setDesignationName(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
          </section>
        </div>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>

            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DESIGNATION CODE</td>
                  <td>DESIGNATION NAME</td>
                  <td>EDIT</td>
                </thead>
                <tbody className="bg-white">
                  {filtDesignations.map((item, index) => (
                    <tr key={index}>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DESIGNATION_CODE
                          ? item.OM_DESIGNATION_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DESIGNATION_NAME
                          ? item.OM_DESIGNATION_NAME
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setDesignationCode(
                            item.OM_DESIGNATION_CODE
                              ? item.OM_DESIGNATION_CODE
                              : ""
                          );
                          setDesignationName(
                            item.OM_DESIGNATION_NAME
                              ? item.OM_DESIGNATION_NAME
                              : ""
                          );
                          hist.current = true;
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className=" cursor-pointer"
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DESIGNATION CODE</td>
                  <td>DESIGNATION NAME</td>
                </thead>
                <tbody>
                  {filtDesignations.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_DESIGNATION_CODE
                          ? item.OM_DESIGNATION_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DESIGNATION_NAME
                          ? item.OM_DESIGNATION_NAME
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignationMaster;

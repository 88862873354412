import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
const MONTHLYDEDUCTIONS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  // console.log(user);
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [leaveType, setLeaveType] = useState("");
  const [listOfLeaveTypes, setListOfLeaveTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searchPopUP, setSearchPopUP] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [AutoDeductionNo, setAutoDeductionNo] = useState(0);
  const [employeeDetails, setemployeeDetails] = useState([]);
  const [HeaderInfo, setHeaderInfo] = useState([]);
  const [Deductedmonthly, setDeductedmonthly] = useState([]);
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [DeductionReason, setDeductionReason] = useState("");
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const hist = useRef(false);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selDeduction, setSelDeduction] = useState(null); // Initialize as null

  const [employeeList, setemployeeList] = useState(null);
  const [deductionType, setDeductionType] = useState("");

  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [startdatePopUp, setstartdatePopUp] = useState(
    localStartOfMonth.toISOString()
  );
  const [enddatePopUp, setEnddatePopUp] = useState(currentDate.toISOString());
  // Filters

  useEffect(() => {
    getLeaveTypes();
  }, []);

  // Fetching List
  const getLeaveTypes = () => {
    axios
      .get(`${BASEURL}/getNewDeductionId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setAutoDeductionNo(res?.data[0].NewID);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Leave Types /getLeaveTypes",
          err
        );
      });
    axios
      .get(`${BASEURL}/allemployees`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setemployeeDetails(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Leave Types /getLeaveTypes",
          err
        );
      });
    axios
      .get(`${BASEURL}/getDeductions`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setDeductedmonthly(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Leave Types /getLeaveTypes",
          err
        );
      });
  };
  console.log(employeeDetails?.[0]?.["DATE JOINED"]);
  const filtHistoricCash = employeeDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["DATE JOINED"]);
    const eDate = new Date(item["DATE JOINED"]);

    if (searchData.length === 0 && !startdatePopUp && !enddatePopUp) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "EMPLOYEE ID",
        "EMPLOYEE CODE",
        "EMPLOYEE NAME",
        "DESIGNATION",
        "DEPARTMENT",
        "NATIONAL ID NO",
      ].some((key) => {
        const columnValue = String(item[key] || "").toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatePopUp || sDate >= new Date(startdatePopUp)) &&
      (!enddatePopUp || eDate <= new Date(enddatePopUp));

    return textMatch && dateMatch;
  });

  const DeductionMatchOptions = [
    { value: "Advance", label: "Advance" },
    { value: "Loan", label: "Loan" },
    { value: "Absenteesm", label: "Absenteesm" },
    { value: "Penalty", label: "Penalty" },
    // Add more employee options here
  ];

  // Filtered data based on user inputs
  const filteredData = Deductedmonthly.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item.PT_DEDUCTION_DATE);
    const eDate = new Date(item.PT_DEDUCTION_DATE);

    const textMatch = searchData.every((term) =>
      [
        "PT_DEDUCTION_ID",
        "PT_DEDUCTION_DATE",
        "PT_DEDUCTION_EMP_ID",
        "PT_DEDUCTION_EMP_CODE",
        "PT_DEDUCTION_TYPE",
        "EMPNAME",
        "PT_DEDUCTION_AMOUNT",
        "PT_DEDUCTION_CURRENCY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      })
    );

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));
    const DeductionMatch =
      !selDeduction ||
      selDeduction?.value?.toLowerCase() ===
        item.PT_DEDUCTION_TYPE.toLowerCase();

    return textMatch && dateMatch && DeductionMatch;
  });

  // console.log("Filtered Data:", filtHistoricCash);
  // Save
  async function Save() {
    setIsLoading(true);
    console.log(
      AutoDeductionNo,
      deductionType,
      deductionAmount,
      DeductionReason,
      employeeCode,
      employeeId,
      fromDate
    );
    const dedcutiondate =
      fromDate.slice(8, 10) +
      "/" +
      fromDate.slice(5, 7) +
      "/" +
      fromDate.slice(0, 4);
    try {
      const response = await axios.post(
        `${BASEURL}/insertEmployeeDeductions`,
        {
          DEDUCTIONID: AutoDeductionNo,
          DEDUCTIONDATE: dedcutiondate,
          EMPID: employeeId,
          EMPCODE: employeeCode,
          DEDUCTIONTYPE: deductionType,
          DEDUCTIONREASON: DeductionReason,
          AMOUNT: deductionAmount,
          CREATEDBY: user,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setIsLoading(false);
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Error while saving Leave Type Details /insertLeaveType`,
        error
      );
      alert(error.response.data.message);
    }
  }

  const handleCreditSaleClick = (row) => {
    setHeaderInfo(row);
    console.log(row);
    setEmployeeCode(row["EMPLOYEE CODE"]);
    setEmployeeName(row["EMPLOYEE NAME"]);
    setEmployeeId(row[" EMPLOYEE ID"]);
    setcashsalepopup(false);
    setDeductionAmount(0);
    setDeductionReason("");
  };

  async function ApproveFunction(confirm) {
    console.log(confirm);
    setIsLoading(true);
    await axios
      .post(
        `${BASEURL}/approveCancelDeductions`,
        {
          DEDUCTIONID: AutoDeductionNo,
          APPROVEDCANCELLED: confirm,
          APPROVEDCANCELLEDBY: user,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert(res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(`error while ${confirm}ing`);
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">
            {menu ? menu : "MONTHLY DEDUCTIONS"}
          </h1>
        </header>
        <div className="container mx-auto lg:w-[95%] p-4 shadow-lg bg-white">
          <div className=" flex lg:w-full gap-3 justify-end mb-2">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              NEW
            </button>
            <button
              onClick={async () => {
                if (!deductionType) {
                  alert("Kindly Enter Deduction Type");
                } else if (!employeeId) {
                  alert("Kindly Enter Employee Id");
                } else if (!deductionAmount) {
                  alert("Kindly Enter Deduction Amount");
                } else if (!DeductionReason) {
                  alert("Kindly Enter Deduction Reason");
                } else {
                  await Save();
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              SAVE
            </button>
            {/* <button
              onClick={() => {
                window.print();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              PRINT
            </button> */}
            <button
              onClick={async () => {
                if (hist.current === false) {
                  alert("Kindly Save the Deduction first");
                } else {
                  await ApproveFunction("APPROVE");
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              APPROVE
            </button>
            <button
              onClick={async () => {
                if (hist.current === false) {
                  alert("Kindly Save the Deduction first");
                } else {
                  await ApproveFunction("CANCEL");
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              CANCEL
            </button>
          </div>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[150px]">
              <label className="block text-gray-700">Deduction No.</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={AutoDeductionNo}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
                {/* <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
                  ...
                </button> */}
              </div>
            </div>
            <div className="mb-4 lg:w-[400px]">
              <label className="block text-gray-700">Deduction Type </label>
              <Select
                value={
                  deductionType
                    ? { label: deductionType, value: deductionType }
                    : { label: "Select Deduction", value: "" }
                }
                onChange={(e) => {
                  setDeductionType(e.value);
                }}
                options={DeductionMatchOptions}
                placeholder="Select Deduction"
                className="w-full"
              />

              {/* <Select
                options={employeeOptions}
                value={employeeOptions.find(
                  (option) => option.value === employeeList
                )}
                onChange={setemployeeList}
                className="w-full"
              /> */}
            </div>

            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700"> Date</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
          </section>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[400px]">
              <label className="block text-gray-700">Employee Name </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={employeeName ? employeeName : ""}
                  // onChange={(e) => setLeaveType(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
                <button
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                  onClick={() => {
                    setcashsalepopup(true);
                    // setHeaderInfo("");
                  }}
                >
                  ...
                </button>
              </div>
              {cashsalepopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] lg:w-[90%] h-[70vh] overflow-auto relative">
                    <div className="lg:flex lg:flex-row mt-2 my-2 ">
                      <p className="text-red-600 ml-2">Search:</p>
                      <input
                        className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        value={searchPopUP}
                        onChange={(e) => {
                          setSearchPopUP(e.target.value);
                        }}
                      ></input>
                      <p className="text-red-600 ml-2">Start Date:</p>
                      <input
                        type="date"
                        className="w-36  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setstartdatePopUp(e.target.value);
                        }}
                        value={startdatePopUp.split("T")[0]}
                      ></input>
                      <p className="text-red-600 ml-2">End Date:</p>
                      <input
                        type="date"
                        className="w-36  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setEnddatePopUp(e.target.value);
                        }}
                        value={enddatePopUp.split("T")[0]}
                      ></input>
                      <button
                        className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                        onClick={() => setcashsalepopup(false)}
                      >
                        Close
                      </button>
                    </div>

                    <aside className="table__pos__app ">
                      <table class="custom-table">
                        <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                          <tr>
                            <td className="text-right ">EMP ID</td>

                            <td className="text-left">EMP CODE</td>
                            <td className="text-left">EMPLOYEE NAME</td>
                            <td className="text-left">JOIN DATE</td>
                            {/* <td className="text-left">EMAIL</td> */}
                            <td className="text-left">DESIGNATION</td>
                            <td className="text-left">DEPARTMENT</td>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {filtHistoricCash.map((row, index) => (
                            <tr
                              key={row.EMPID}
                              onClick={() => {
                                handleCreditSaleClick(row);
                              }}
                              className={
                                index < filtHistoricCash.length
                                  ? "border-b cursor-pointer table-row"
                                  : ""
                              }
                            >
                              <td className="text-right pr-2">
                                {row[" EMPLOYEE ID"] ? row[" EMPLOYEE ID"] : ""}
                              </td>
                              <td className=" pr-2">
                                {row["EMPLOYEE CODE"]
                                  ? row["EMPLOYEE CODE"]
                                  : ""}
                              </td>
                              <td className=" pr-2">
                                {row["EMPLOYEE NAME"]
                                  ? row["EMPLOYEE NAME"]
                                  : ""}
                              </td>
                              <td>
                                {row["DATE JOINED"]
                                  ? moment(
                                      row["DATE JOINED"].split("T")[0]
                                    ).format("DD/MM/YYYY")
                                  : ""}
                              </td>
                              {/* <td>{row["EMAIL"] ? row["EMAIL"] : ""}</td> */}
                              <td>
                                {row["DESIGNATION"] ? row["DESIGNATION"] : ""}
                              </td>
                              <td>
                                {row["DEPARTMENT"] ? row["DEPARTMENT"] : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </aside>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">Employee Code</label>
              <input
                type="test"
                value={employeeCode ? employeeCode : ""}
                // onChange={(e) => setEmployeeCode(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">Employee ID </label>
              <input
                type="test"
                value={employeeId ? employeeId : ""}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">Deduction Amount </label>
              <input
                type="test"
                value={deductionAmount}
                onChange={(e) => setDeductionAmount(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
          </section>
          <section className="lg:flex gap-5 w-full justify-between">
            <div className="mb-4 lg:w-[800px]">
              <label className="block text-gray-700">Deduction Reason</label>
              <div className="flex gap-3">
                <textarea
                  type="text"
                  value={DeductionReason}
                  onChange={(e) => setDeductionReason(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
              </div>
            </div>
          </section>
        </div>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded     gap-5">
          <h1 className="font-bold mt-2">Deduction Of selected Period :</h1>

          <section className="lg:flex items-center gap-3">
            <div className="lg:flex items-center gap-3">
              <p>Deduction Type</p>
              <Select
                value={selDeduction}
                onChange={setSelDeduction}
                options={DeductionMatchOptions}
                placeholder="Select Deduction"
                isClearable
                className="w-[200px]"
              />
            </div>

            <div className=" lg:w-[300px] lg:flex items-center">
              <label className="block text-gray-700 lg:w-[130px]">
                From Date
              </label>
              <input
                type="date"
                onChange={(e) => {
                  setstartdate(e.target.value);
                }}
                value={startdate.split("T")[0]}
                placeholder="From Date"
              />
            </div>
            <div className=" lg:w-[300px] lg:flex items-center">
              <label className="block text-gray-700 lg:w-[130px]">
                End Date
              </label>
              <input
                type="date"
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
                value={enddate.split("T")[0]}
                placeholder="To Date"
              />
            </div>
          </section>
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>

            <aside className="table__video__app uppercase text-sm">
              <table class="custom-table">
                <thead class="header-cell">
                  <td> DEDUCTION Id</td>
                  <td> DEDUCTION DATE</td>
                  <td> DEDUCTION TYPE</td>
                  <td> EMPLOYEE NAME</td>
                  <td>EMPLOYEE ID</td>
                  <td> EMPLOYEE CODE</td>

                  <td> DEDUCTIONS AMOUNT</td>
                  <td> DEDUCTIONS CURRENCY</td>

                  <td>EDIT</td>
                </thead>
                <tbody className="bg-white">
                  {filteredData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-right">
                        {item.PT_DEDUCTION_ID ? item.PT_DEDUCTION_ID : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_DATE
                          ? moment(
                              item.PT_DEDUCTION_DATE?.split("T")[0]
                            ).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_TYPE
                          ? item.PT_DEDUCTION_TYPE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.EMPNAME ? item.EMPNAME : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.PT_DEDUCTION_EMP_ID
                          ? item.PT_DEDUCTION_EMP_ID
                          : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_EMP_CODE
                          ? item.PT_DEDUCTION_EMP_CODE
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.PT_DEDUCTION_AMOUNT
                          ? item.PT_DEDUCTION_AMOUNT
                          : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_CURRENCY
                          ? item.PT_DEDUCTION_CURRENCY
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          hist.current = true;
                          setAutoDeductionNo(item.PT_DEDUCTION_ID);
                          setDeductionType(item.PT_DEDUCTION_TYPE);
                          console.log(item.PT_DEDUCTION_TYPE);
                          setDeductionAmount(item.PT_DEDUCTION_AMOUNT);
                          setDeductionReason(item.PT_DEDUCTION_NARRATION);
                          setEmployeeCode(item["PT_DEDUCTION_EMP_CODE"]);
                          setEmployeeName(item["EMPNAME"]);
                          setEmployeeId(item["PT_DEDUCTION_EMP_ID"]);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className=" cursor-pointer"
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td> DEDUCTION Id</td>
                  <td> DEDUCTION DATE</td>
                  <td> DEDUCTION TYPE</td>
                  <td> EMPLOYEE NAME</td>
                  <td>EMPLOYEE ID</td>
                  <td> EMPLOYEE CODE</td>

                  <td> DEDUCTIONS AMOUNT</td>
                  <td> DEDUCTIONS CURRENCY</td>

                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="text-right">
                        {item.PT_DEDUCTION_ID ? item.PT_DEDUCTION_ID : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_DATE
                          ? moment(
                              item.PT_DEDUCTION_DATE?.split("T")[0]
                            ).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_TYPE
                          ? item.PT_DEDUCTION_TYPE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.EMPNAME ? item.EMPNAME : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.PT_DEDUCTION_EMP_ID
                          ? item.PT_DEDUCTION_EMP_ID
                          : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_EMP_CODE
                          ? item.PT_DEDUCTION_EMP_CODE
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.PT_DEDUCTION_AMOUNT
                          ? item.PT_DEDUCTION_AMOUNT
                          : "N/A"}
                      </td>
                      <td className="">
                        {item.PT_DEDUCTION_CURRENCY
                          ? item.PT_DEDUCTION_CURRENCY
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setAutoDeductionNo(item.PT_DEDUCTION_ID);
                          setDeductionType(item.PT_DEDUCTION_TYPE);
                          console.log(item.PT_DEDUCTION_TYPE);
                          setDeductionAmount(item.PT_DEDUCTION_AMOUNT);
                          setDeductionReason(item.PT_DEDUCTION_NARRATION);
                          setEmployeeCode(item["PT_DEDUCTION_EMP_CODE"]);
                          setEmployeeName(item["EMPNAME"]);
                          setEmployeeId(item["PT_DEDUCTION_EMP_ID"]);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MONTHLYDEDUCTIONS;
